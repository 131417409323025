.hero-container{
  background-image: url("https://images.pexels.com/photos/40568/medical-appointment-doctor-healthcare-40568.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260");
  background-color: #cccccc;
  height: 100vh;
  background-size: cover;
  margin-bottom: 2rem;
}

.navbar-contain{
  background-color: white;
  opacity: .5;
}

.navbar-svg{
  padding: 1rem;
  text-align: center;
}

.hero-card{
  width: 50vw;
  padding: 2rem;
  display: flex;
  align-items: center;
  background-color: black;
  opacity: .6;
  color: white;
  border-radius: 30px;
  margin-top: 20vh;
  text-align: center;
flex-direction: column;
}

.hero-card-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-contain{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-card{
  width: 80vw;
  margin: 2rem;
}

.about-div{
  width: 80vw;
  text-align: center;
  margin: auto;
  background-color: #0c5d83;
  padding: 2rem;
  color: white;
}

.about-div-title{
  color: #72CAF2;
}

.about-img{
  width: 80vw;
}

.about-section{
  text-align: center;
}

.about-title-contain{
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.about-title{
  border-bottom: 5px solid #72CAF2;
  width: max-content;
  padding-bottom: .5rem;
  margin: 0 0 2rem;
}

.about-p{
  background-color: #ED1C24;
  color: #fff;
  font-weight: bold;
  padding: 1rem;
  width: max-content;
  border-radius: 30px;
  margin: 1rem;
}

.about-variant{
  background-color: #72CAF2;
  color: black;
}

.about-value{
  text-align: center;
}

.about-value-p{
  margin-bottom: 4rem;
}

.service-card{
  width: 80vw;
  padding: 2rem;
  display: flex;
  align-items: center;
  background-color: black;
  color: white;
  border-radius: 30px;
  text-align: center;
  flex-direction: column;
  margin: 2rem 0;
}

.service-card-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-container{
  background-color: #0c5d83;
  opacity: .7;
}

.service-span{
  font-weight: bold;
  color: #0c5d83;
}

.footer-contain{
  background-color: black;
  opacity: .7;
  color: white;
  font-weight: bold;
  letter-spacing: 4px;
}

.footer-svg{
  padding: 1rem;
  text-align: center;
}

@media (min-width: 600px) {
  .navbar-svg{
    padding: 1rem;
    text-align: left;
  }
  .hero-card{
    width: 30vw;
    margin-top: 25vh;
  }
  .about-contain{
    display: flex;
    flex-direction: row;
  }
  .about-card{
    width: 30vw;
    margin: 2rem;
  }
  .about-img{
    width: 30vw;
  }
  .about-div{
    width: 40vw;
  }
  .service-card{
    width: 50vw;
  }
}